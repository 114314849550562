
let SocialLink=[
    {icon:"mdi-facebook",link:"",title:"facebookUrl"},
    {icon:"mdi-twitter",link:"",title:"twitterUrl"},
    {icon:"mdi-linkedin",link:"",title:"linkInUrl"},
    {icon:"mdi-instagram",link:"",title:"instagramUrl"},
];

let TabList=[
    {name:"home",to:"/home"},
    // {name:"presidentWelcome",to:"/rector-welcome"},
    {name:"ourProduct",to:"/"},
    {name:"ourClient",to:"/our-client"},
    {name:"organization",to:"/organization"},
    // {name:"qualitySystem",to:"/quality-system"},
    {name:"newsAndEvents",to:"/news-events"},
    {name:"productionLine",to:"/production-line"},
    {name:"about",to:"/about"},
    {name:"contact",to:"/contact"},

];

let LanguageList=[
    {name:"ភាសារខ្មែរ",value:"km"},
    {name:"English",value:"en"},
    {name:"中文",value:"cn"}
]
let MissionList=[
    {title:"mission1"},
    {title:"mission2"},
    {title:"mission3"},
    {title:"mission4"},
    {title:"mission5"}
]
let PhoneNumberList=[
    "+855 12 774 787" ,
    "+855 12 579 908"
]

let DepartmentList=[
    {title:"it_department",body:"",color:"#1F7087",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FFB_IMG_1606119786228%20-%20Coding%20Toy.jpg?alt=media&token=594f5bc8-8f00-4597-96ac-7db6e9cd3b2b"},
    {title:"electronic_department",body:"",color:"#385F73",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Faaaaaaaaaaaaaaaaa%20-%20%E1%9E%8F%E1%9E%BB%E1%9E%9B%E1%9E%B6%20%E1%9E%88%E1%9E%BA%E1%9E%93-min.png?alt=media&token=80c46684-435a-4309-aa96-8dc95b34ec57"},
    {title:"electricity_department",body:"",color:"#8A348A",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F8%20-%20bev%20chanthorn.jpg?alt=media&token=b6267c77-4373-4634-a3c1-9f14a73962d9"},
    {title:"mechanic_department",body:"",color:"#B42B59",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG20210519193411%20-%20SR%20electricity%20Electrical%20Engineering%20Technology-min.jpg?alt=media&token=d886637e-69a1-4502-a967-060cd363484e"},
    {title:"agriculture_and_food_processing_department",body:"",color:"#3D6BE7",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F0E41FF81-BFC5-466E-AF69-C180435BB449%20-%20kung%20menghak-min.jpeg?alt=media&token=cbe94925-f703-4c89-a54e-1ae727335f67"},
    {title:"foreign_language_department",body:"",color:"#952175",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FB612_20210901_153603_472%20-%20SENGHEANG%20YIN-min.jpg?alt=media&token=fdc4f020-6b9e-42a3-bf23-689c892531cd"},

]
let ScheduleWeekday=[
    {time:"morning",value:"07:30 am - 12:00 pm"},
    {time:"afternoon",value:"01:00 pm - 05:30 pm"},
    {time:"evening",value:"05:30 pm - 08:30 pm"},
]
let ScheduleWeekend=[
    {time:"morning",value:"07:30 am - 12:00 pm"},
    {time:"afternoon",value:"01:30 pm - 06:00 pm"},
]
export const Constants={
    socialLink:SocialLink,
    languageList:LanguageList,
    departmentList:DepartmentList,
    scheduleWeekday:ScheduleWeekday,
    scheduleWeekend:ScheduleWeekend,
    missionList:MissionList,
    phoneNumberList:PhoneNumberList,
    tabList:TabList,
    //baseURL:"http://localhost:3000/api/v1/",
    baseURL:"https://entry.phoupoyricemill.com/api/v1/",
    token:"narongwebsite",
    //branchId:"SQ8EoPSvdTbe8Mbb4",
    branchId:"wrdmyzBcK8LMDg3mb",
    api:{
        getHome:"home/fetch",
        getAbout:"about/fetch",
        getContact:"contact/fetch",
        getProduct:"product/fetch",
        getNewsAndEvent:"newsAndEvents/fetch",
        getClient:"client/fetch",
        getOrganization:"organization/fetch",
        getProductionLine:"productionLine/fetch",
        getHeaderFooter:"headerFooter/fetch",
        getProductReport:"productReport/fetch",
    },


}