import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import moment from "moment";

Vue.config.productionTip = false
Vue.filter("formatDate", (d) => {
    if (!d) return "";
    return moment(d, "YYYY-MM-DD").format("DD/MMM/YYYY");
})


import MyMixin from "@/mixins/mixin"

Vue.mixin(MyMixin);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
