import news from "@/db/news.json"
import medias from "@/db/media.json"
import ourProducts from "@/db/ourProducts.json";
import qualitySystem from "@/db/qualitySystem.json";


export default {
    data() {
        return {

        }
    },
    methods: {
        getNews() {
            return news;
        },
        getOurProduct() {
            return ourProducts;
        },
        getQualitySystem() {
            return qualitySystem;
        },
        paginate(array, page_size, page_number) {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        },
        getMedia() {
            return medias;
        },
        getTranslate(data) {
            if (data) {
                if (this.$i18n.locale === 'en') {
                    return data.en;
                } else if (this.$i18n.locale === 'km') {
                    return data.km || data.en;
                } else {
                    return data.cn || data.en;
                }
            } else {
                return "";
            }
        },
    }
}