<template>
  <div>
    <tab-list style="top:60px;z-index: 99"></tab-list>
<!--    <img src="../../assets/rice.png" :height="733*0.2" :width="920*0.2"-->
<!--         style="position: absolute; left: 40px;top:100px;z-index: 99" class="animation-round"/>-->
    <v-carousel
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
        style="border-bottom-left-radius: 40%;"
    >
<!--      cycle-->
      <!--      :show-arrows="false"-->
<!--      hide-delimiters-->
      <template v-slot:prev="{ on, attrs }">
        <v-btn
            color="success"
            v-bind="attrs"
            v-on="on"
        >Previous slide
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
            color="info"
            v-bind="attrs"
            v-on="on"
        >Next slide
        </v-btn>
      </template>

      <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="slide.url"
      >

        <v-sheet
            :color="colors[i]"
            height="103%"
        >
          <v-row
              class="fill-height"
              align="center"
              justify="center"
              style="margin-left: 10px;margin-right: 10px;background-color: transparent;color: white"
          >

            <img class="animate__animated animate__fadeIn  animate__delay-1s" src="../../assets/logo.png" :height="321*0.50"/>
            <div :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
                 style="font-family: 'fantasy';font-weight: bold">
              <p style="font-family: 'Khmer OS Muol Light';font-weight: normal;"
                 class="animate__animated animate__slideInLeft animate__duration-5s">{{ slide.km }}</p>
              <p class="animate__animated animate__fadeInUp animate__delay-1s">{{ slide.en }}</p>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>

</template>
<script>
import {Constants} from "@/libs/constant";
import TabList from "@/components/tabList";

export default {
  components: {TabList},
  data() {
    return {
      colors: [
        // 'rgb(79, 195, 247,0.8)',
        // 'rgb(255, 255, 0,0.6)',
        // 'rgb(253,254,39,0.6)',
        // 'rgb(253,254,39,0.6)',
        // 'rgb(253,254,39,0.6)',
        'rgb(14, 64, 106,0.6)',
        'rgb(14, 64, 106,0.6)',
        'rgb(14, 64, 106,0.6)',
        //'rgb(14, 64, 106,0.6)',
        // 'rgb(231, 100, 38,0.9)',
        // 'rgb(231, 248, 238,0.9)',
      ],
      slides: [
        {
          km: 'ភួ ប៉ូយ ឌីវេឡូបមេន អុីមផត អុិចផត',
          en: "Phou Poy Development Import Export Co. Ltd.",
          url: "https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/footer%2F5bc7f35e116b1-min.jpeg?alt=media&token=b68a5c76-290d-4781-aef4-74ce2d9a8193"
        },
        {
          km: '',
          en: "We provide high-quality rice products",
          url: "https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/rice%2Fphoto_2021-10-21_19-49-42.jpg?alt=media&token=731aebf0-8548-409d-9ac8-d1d3fab7a9c8"
        },
        {
          km: '',
          en: "All Fragrant and non-fragrant varieties with all grades",
          url: "https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/footer%2Fcambodiapadd-min.jpeg?alt=media&token=e83a0e1c-54ff-4b77-9c05-86f5571734bf"
        },

      ],
      tabList: Constants.tabList,


    }
  },
}
</script>

<style lang="css" scoped>

.animation-round {
  animation: mymove 5s infinite;
  transition: all 2s ease;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes mymove {
  0% {
    left: 25px;
    top: 50px;
  }
  25% {
    left: 30px;
    top: 55px;
  }
  50% {
    left: 27px;
    top: 52px;
  }
  75% {
    left: 25px;
    top: 50px;
  }
  100% {
    left: 23px;
    top: 45px;
  }
}
</style>