<template>
  <v-footer
      light
      padless

  >
    <v-card
        flat
        tile
        style="background-image: url('https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/footer%2Fpic1.jpeg?alt=media&token=d27d8974-6895-4efa-8d1b-c5146a2fa30d');background-size: cover; width: 100%"
        class="blue-grey darken-2 white--text text-center"
    >
      <v-sheet
          :color="colors"
          height="103%"
      >

        <v-card-text style="color: white">
          <v-btn icon class="mx-4 white--text" v-if="dataFooter.facebookUrl"
                 style="margin-left: 0px !important;margin-right: 0px !important;"
                 :href="dataFooter.facebookUrl" target="_blank">
            <v-icon size="24px">mdi-facebook</v-icon>
          </v-btn>
          <v-btn icon class="mx-4 white--text" v-if="dataFooter.instagramUrl"
                 style="margin-left: 0px !important;margin-right: 0px !important;"
                 :href="dataFooter.instagramUrl" target="_blank">
            <v-icon size="24px">mdi-instagram</v-icon>
          </v-btn>
          <v-btn icon class="mx-4 white--text" v-if="dataFooter.twitterUrl"
                 style="margin-left: 0px !important;margin-right: 0px !important;"
                 :href="dataFooter.twitterUrl" target="_blank">
            <v-icon size="24px">mdi-twitter</v-icon>
          </v-btn>
          <v-btn icon class="mx-4 white--text" v-if="dataFooter.linkInUrl"
                 style="margin-left: 0px !important;margin-right: 0px !important;"
                 :href="dataFooter.linkInUrl" target="_blank">
            <v-icon size="24px">mdi-linkedin</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="pt-0 white--text">
          <v-row :style="$vuetify.breakpoint.mobile ? '' : 'padding-left:10%;padding-right:10%'">
            <v-col cols="12" md="4" sm="4">
              <img src="@/assets/logo.png" :height="321*0.15"/>
              <h1 style="padding-top: 15px;font-family: Roboto">PPD</h1>
              <br>
              <p style="text-align: left"><span
                  v-html="getTranslate(dataFooter.addressFooter)"></span></p>
              <v-list-item class="white--text">
                <v-list-item-icon>
                  <v-btn small fab color="success">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content style="padding-top: 0px !important;padding-bottom: 0px !important;">
                  <v-list-item-title style="text-align: start !important;" v-html="dataFooter.phoneNumberFooter"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="white--text">
                <v-list-item-icon>
                  <v-btn small fab color="primary">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="text-align: start" v-html="dataFooter.emailFooter"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('aboutThisSite') }}</h1><br><br>
              <p style="text-align: justify" v-html="getTranslate(dataFooter.aboutThisSite)"></p>
              <v-divider></v-divider>

            </v-col>

            <v-col cols="12" md="4" sm="4">
              <div class="white--text">
                <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('quickLink') }}</h1>
                <br>
                <v-list-item
                    v-for="(item, index) in tabList"
                    :key="index"
                    class="white--text"
                    :to="item.to"
                >
                  <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                </v-list-item>

              </div>
            </v-col>
          </v-row>
        </v-card-text>


        <v-divider></v-divider>
      </v-sheet>


    </v-card>
    <v-card-text class="white--text" style="background-color: darkgoldenrod">
      2021 — <strong>
      <v-icon style="color: white">mdi-copyright</v-icon>
      PPD | Developed by ZIB Solution</strong>
    </v-card-text>
  </v-footer>
</template>

<script>
import {Constants} from "@/libs/constant";

export default {
  props: ["dataFooter"],
  data: () => ({
    tabList: Constants.tabList,

    colors: "rgb(14, 64, 106,0.6)",
    //colors: "rgb(253,254,39,0.6)",
    socialList: Constants.socialLink,
    phoneNumberList: Constants.phoneNumberList,
    scheduleWeekday: Constants.scheduleWeekday,
    scheduleWeekend: Constants.scheduleWeekend,
    email: "kimeng_phou@yahoo.com",
    missionList: Constants.missionList
  }),
}
</script>