var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold-hexagon-outline")])],1),_c('v-app-bar',{staticClass:"fixTitle",style:(_vm.$vuetify.breakpoint.mobile ? 'height: 40px !important;background: darkgoldenrod;padding-left: 0px !important;padding-right: 0px !important;margin:0px !important' : 'height: 40px !important;background: darkgoldenrod;padding-left: 40px !important;padding-right: 40px !important;'),attrs:{"dark":""}},[(!_vm.$vuetify.breakpoint.mobile)?_c('h5',[_c('v-icon',{staticStyle:{"font-size":"15px"}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.doc.phoneNumberHeader)+" "),_c('v-icon',{staticStyle:{"font-size":"15px","padding-left":"50px"}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.doc.emailHeader)+" ")],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('h6',[_c('v-icon',{staticStyle:{"font-size":"15px"}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.doc.phoneNumberHeader)+" "),_c('v-icon',{staticStyle:{"font-size":"15px","padding-left":"10px"}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.doc.emailHeader)+" ")],1):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2084382696)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.tabList),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.name)))])],1)}),1)],1)],1):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('div',[(_vm.doc.facebookUrl)?_c('v-btn',{staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":"","href":_vm.doc.facebookUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-facebook")])],1):_vm._e(),(_vm.doc.instagramUrl)?_c('v-btn',{staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":"","href":_vm.doc.instagramUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-instagram")])],1):_vm._e(),(_vm.doc.twitterUrl)?_c('v-btn',{staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":"","href":_vm.doc.twitterUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-twitter")])],1):_vm._e(),(_vm.doc.linkInUrl)?_c('v-btn',{staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":"","href":_vm.doc.linkInUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-linkedin")])],1):_vm._e()],1):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 white--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.languageLabel)+" ")])]}}],null,false,817920538)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.languageList),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeLanguage(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1):_vm._e()],1),_c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fixed":"","color":"white","light":""}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/logo.png"),"height":321*0.15}}),_c('h1',{staticStyle:{"float":"right","padding-top":"0px","color":"blue","font-family":"Roboto"}},[_vm._v("PPD")])]),(!_vm.$vuetify.breakpoint.mobile)?_c('tab-list'):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 black--text",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.languageLabel)+" ")])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.languageList),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeLanguage(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 text--darken-1",staticStyle:{"margin-left":"0px !important","margin-right":"0px !important"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,489689668)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.tabList),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.name)))])],1)}),1)],1)],1):_vm._e()],1),_c('Carousel1'),_c('v-main',{staticStyle:{"min-height":"1400px"}},[_c('div',{staticClass:"shape-feature d-xl-block"},[_c('img',{staticClass:"shape-1",attrs:{"src":require("@/assets/other/01.png"),"alt":"feature-thumb"}}),_c('img',{staticClass:"shape-2",attrs:{"src":require("@/assets/other/02.png"),"alt":"feature-thumb"}}),_c('img',{staticClass:"shape-3",attrs:{"src":require("@/assets/other/03.png"),"alt":"feature-thumb"}})]),_c('router-view'),_c('Footer',{attrs:{"dataFooter":_vm.doc}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }