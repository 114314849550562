<template>
    <v-tabs style="position: absolute;left: 15%;width: 70%;font-family: 'Khmer OS Battambang'" v-if="!$vuetify.breakpoint.mobile">
      <v-tab to="/home">
        <div>{{ $t('home') }}</div>
      </v-tab>
<!--      <v-tab to="/rector-welcome">-->
<!--        <div>{{ $t('presidentWelcome') }}</div>-->
<!--      </v-tab>-->
      <v-tab to="/">
        <div>{{ $t('ourProduct') }}</div>
      </v-tab>
      <v-tab to="/our-client">
        <div>{{ $t('ourClient') }}</div>
      </v-tab>
      <v-tab to="/organization">
        <div>{{ $t('organization') }}</div>
      </v-tab>
<!--      <v-tab to="/quality-system">-->
<!--        <div>{{ $t('qualitySystem') }}</div>-->
<!--      </v-tab>-->
      <v-tab to="/news-events">
        <div>{{ $t('newsAndEvents') }}</div>
      </v-tab>
      <v-tab to="/production-line">
        <div>{{ $t('productionLine') }}</div>
      </v-tab>
      <v-tab to="/about">
        <div>{{ $t('about') }}</div>
      </v-tab>
      <v-tab to="/contact">
        <div>{{ $t('contact') }}</div>
      </v-tab>
    </v-tabs>
</template>
<script>
  import {Constants} from "@/libs/constant"
  export default {
    name:"TabList",
    data(){
      return {
        curriculumList: Constants.tabList[1].subMenu,
        structureList: Constants.tabList[2].subMenu,
        cooperationList: Constants.tabList[3].subMenu,
      }
    }
  }
</script>