import Vue from 'vue'
import Router from 'vue-router'
//import Home from '../views/home.vue'

Vue.use(Router)

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
        path: '/',
        name: 'OuProduct',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '../views/our_product')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
    },

    {
        path: '/rector-welcome',
        name: 'RectorWelcome',
        component: () => import(/* webpackChunkName: "RectorWelcome" */ '../views/rectorWelcome')
    },

    {
        path: '/news-events-detail',
        name: 'NewsEventsDetail',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/new_and_event_detail')
    },
    {
        path: '/news-events',
        name: 'NewsEvents',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/new_and_event')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/home')
    },
    {
        path: '/our-client',
        name: 'OurClient',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/client')
    },
    {
        path: '/organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/organization')
    },
    {
        path: '/production-line',
        name: 'productionLine',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/productionLine')
    },
    {
        path: '/quality-system',
        name: 'QualitySystem',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/quality_system')
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }


    }
})

export default router

