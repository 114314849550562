<template>
  <v-app>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-arrow-up-bold-hexagon-outline</v-icon>
    </v-btn>
    <v-app-bar
        dark
        :style="$vuetify.breakpoint.mobile ? 'height: 40px !important;background: darkgoldenrod;padding-left: 0px !important;padding-right: 0px !important;margin:0px !important' : 'height: 40px !important;background: darkgoldenrod;padding-left: 40px !important;padding-right: 40px !important;'"
        class="fixTitle"
    >
      <h5 v-if="!$vuetify.breakpoint.mobile">
        <v-icon style="font-size: 15px">mdi-phone</v-icon>
        {{ doc.phoneNumberHeader }}
        <v-icon style="font-size: 15px;padding-left: 50px">mdi-email</v-icon>
        {{ doc.emailHeader }}
      </h5>
      <h6 v-if="$vuetify.breakpoint.mobile">
        <v-icon style="font-size: 15px">mdi-phone</v-icon>
        {{ doc.phoneNumberHeader }}
        <v-icon style="font-size: 15px;padding-left: 10px">mdi-email</v-icon>
        {{ doc.emailHeader }}
      </h6>
      <v-spacer/>
      <!--      <v-btn icon @click="changeLanguage()" v-if="!$vuetify.breakpoint.mobile">-->
      <!--        <v-img v-if="language==='en'" style="height: 20px !important;width: 20px !important;"-->
      <!--               src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"></v-img>-->
      <!--        <v-img v-if="language==='km'" style="height: 20px !important;width: 20px !important;"-->
      <!--               src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg"></v-img>-->
      <!--      </v-btn>-->


      <v-menu offset-y v-if="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-4 white--text" style="margin-left: 0px !important;margin-right: 0px !important;"
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(item, index) in tabList"
                :key="index"
                :to="item.to"
            >
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <div v-if="!$vuetify.breakpoint.mobile">
        <v-btn icon class="mx-4 white--text" v-if="doc.facebookUrl"
               style="margin-left: 0px !important;margin-right: 0px !important;"
               :href="doc.facebookUrl" target="_blank">
          <v-icon size="24px">mdi-facebook</v-icon>
        </v-btn>
        <v-btn icon class="mx-4 white--text" v-if="doc.instagramUrl"
               style="margin-left: 0px !important;margin-right: 0px !important;"
               :href="doc.instagramUrl" target="_blank">
          <v-icon size="24px">mdi-instagram</v-icon>
        </v-btn>
        <v-btn icon class="mx-4 white--text" v-if="doc.twitterUrl"
               style="margin-left: 0px !important;margin-right: 0px !important;"
               :href="doc.twitterUrl" target="_blank">
          <v-icon size="24px">mdi-twitter</v-icon>
        </v-btn>
        <v-btn icon class="mx-4 white--text" v-if="doc.linkInUrl"
               style="margin-left: 0px !important;margin-right: 0px !important;"
               :href="doc.linkInUrl" target="_blank">
          <v-icon size="24px">mdi-linkedin</v-icon>
        </v-btn>
      </div>

      <v-menu offset-y v-if="!$vuetify.breakpoint.mobile" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined class="mx-4 white--text" style="margin-left: 0px !important;margin-right: 0px !important;"
                 v-bind="attrs"
                 v-on="on">
            {{ languageLabel }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(item, index) in languageList"
                :key="index"
                @click="changeLanguage(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-app-bar
        fixed
        color="white"
        light
        v-show="fab"
    >
      <div>
        <img src="@/assets/logo.png" :height="321*0.15"/>
        <h1 style="float: right;padding-top: 0px;color: blue;font-family: Roboto">PPD</h1>
      </div>
      <tab-list v-if="!$vuetify.breakpoint.mobile"></tab-list>

      <v-spacer></v-spacer>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined class="mx-4 black--text" style="margin-left: 0px !important;margin-right: 0px !important;"
                 v-bind="attrs"
                 v-on="on">
            {{ languageLabel }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(item, index) in languageList"
                :key="index"
                @click="changeLanguage(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu offset-y v-if="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-4 text--darken-1" style="margin-left: 0px !important;margin-right: 0px !important;"
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
              color="primary"
          >
            <v-list-item
                v-for="(item, index) in tabList"
                :key="index"
                :to="item.to"
            >
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>


    <Carousel1></Carousel1>

    <v-main style="min-height: 1400px;">
      <div class="shape-feature d-xl-block">
        <img src="@/assets/other/01.png" alt="feature-thumb" class="shape-1">
        <img src="@/assets/other/02.png" alt="feature-thumb" class="shape-2">
        <img src="@/assets/other/03.png" alt="feature-thumb" class="shape-3">
      </div>
      <router-view/>
      <Footer :dataFooter="doc"></Footer>
    </v-main>

  </v-app>
</template>

<script>
import Footer from "@/views/layout/footer";
import Carousel1 from "@/views/layout/carousel1";
import {Constants} from "@/libs/constant";
import TabList from "@/components/tabList";
import axios from "axios";
import 'animate.css';

export default {
  name: 'App',
  components: {Footer, Carousel1, TabList},
  data() {
    return {
      socialList: Constants.socialLink,
      languageList: Constants.languageList,
      tabList: Constants.tabList,

      languageLabel: "English",
      fab: false,
      doc: {}

    }
  },
  methods: {
    changeLanguage(doc) {
      let vm = this;
      vm.$i18n.locale = doc.value;
      vm.languageLabel = doc.name;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    fetchHeaderFooter() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHeaderFooter;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.doc = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    }
  },
  created() {
    this.fetchHeaderFooter();
  }
};
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  font-family: "Open Sans", "Khmer OS Battambang", "Heiti SC";
}

body {
  font-family: "Khmer OS Battambang";
}

.fixTitle .v-toolbar__content {
  height: 40px !important;
}

.sub-1 {
  text-align: justify;
  font-family: "Khmer OS Battambang";
}

.hover02 figure img {
  width: 95px;
  height: auto;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hover02 figure:hover img {
  width: 140px;
}

figure {
  width: 100px;
  height: 130px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #2b2e2e;
}


.ten_tagline:after, .ten_tagline--fill:after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100% - 12px);
  border-top: 1px solid rgba(25, 74, 195, 1);
}

.ten_tagline:before, .ten_tagline--fill:before {
  content: "";
  display: block;
  position: absolute;
  left: calc(100% - 12px);
  bottom: 0px;
  width: 100%;
  border-top: 1px solid rgba(25, 74, 195, 1);
  transform: rotate(
          -69deg) translate(-1px, 0px);
  transform-origin: 0px 0px;
}

.ten_tagline, .ten_tagline--fill {
  font-family: 'TencentSansw7';
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-top: 1px solid rgba(25, 74, 195, 1);
  padding: 0px 30px;
  color: rgba(25, 74, 195, 1);
}

.ten_tagline {
  border-left: 1px solid rgba(25, 74, 195, 1);
}

.bBottom {
  border-bottom: 1px solid #f4f4f4;
}



.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table td, .table th {
  padding: .75rem;

}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.tablePadding td, .tablePadding th {
  padding: .25rem;

}

.tablePadding {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

table {
  border-collapse: collapse;
}

table {
  background-color: transparent !important;
}

.tableCollapse > tr:nth-child(even) {
  background-color: #dddddd;
}

.d-xl-block {
  display: block!important;
}

.shape-feature img.shape-1 {
  top: 0;
  right: 0;
  position: absolute;
}

.shape-feature img.shape-2 {
  bottom: 60%;
  left: 0;
  position: absolute;
}
.shape-feature img.shape-3 {
  bottom: 30%;
  right: 0;
  position: absolute;
}
</style>
